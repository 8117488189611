
.group-course-card {
    display: block;
}

.group-course-form {
    background-color: #ffffff;
    padding: 20px;
}

.group-course-form label {
    display: block;
    padding-bottom: 10px;
}

.group-course-form input {
    display: block;
    margin-bottom: 20px;
}