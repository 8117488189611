.confirm-modal-container > h1 {
    margin-bottom: 0;
}

.confirm-modal-body {
    padding: 20px;
    max-height: 500px;
    overflow: scroll;
}

.broadcast-assignments-list {
    padding: 0;
    margin: 0;
}

.broadcast-assignments-list > li {
    display: block;
    border-bottom: 1px solid;
    width: 100%;
    padding: 20px 0;
}

.broadcast-assignment-header {
    padding-bottom: 20px;
}

.broadcast-assignment-header > label {
    font-size: 17px;
    font-weight: 500;
}

.broadcast-channels-list-container {
    display: flex;
    flex-direction: row;
    align-items: top;
}

.broadcast-channels-list {
    width: 50%;
}

.broadcast-channels-list > h4 {
    padding-bottom: 10px;
    padding-left: 32px;
}

.broadcast-channels-list ul {
    margin: 0;
    padding: 0;
}

.broadcast-channels-list ul > li {
    display: block;
}

.broadcast-textarea {
    font-family: unset;
    width: 100%;
    min-height: 150px
}