.note-composer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 36px;
}

.note-editor {
    outline: 1px solid #c6c6c8;
    border-radius: 10px;
}

.ProseMirror {
    padding: 16px;
}

.ProseMirror:focus-visible {
    outline: none;
}

.note-menubar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 24px;
}

.note-inputs {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    width: 100%;
}

.note-title-container {
    display: flex;
    flex-direction: column;
}

.note-attachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px;
    outline: 1px solid #c6c6c8;
}

.uploaded-note-attachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px;
}

.note-body-container {
    border-radius: 8px;
    outline: 1px solid #c6c6c8;
}

.note-attachment-cell {
    display: flex;
    flex-direction: row;
    padding: 15px;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 2px 6px rgba(171, 171, 171, 0.25);
    border-radius: 8px;
    gap: 0.25rem;
}

.note-attachment-cell > img[alt="attachment"] {
    height: 20px;
}
.note-attachment-cell > img[alt="remove attachment"] {
    cursor: pointer;
    height: 10px;
    margin-left: 0.3rem;
}

.composer-toolbar {
    display: flex;
    align-items: flex-start;
    padding: 8px;
    padding-top: 14px;
    border-bottom: 1px solid #c6c6c8;
}

.composer-toolbar > * {
    padding: 8px;
}

.composer-toolbar > button {
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-weight: 600;
    color: #9e9e9e;
    border-radius: 4px;
    height: 35px;
}

.composer-toolbar > button:hover {
    background-color: #e9e9e9;
}

.composer-toolbar > button.is-active {
    background-color: #d9d9d9;
}

.note-composer label {
    font-size: 16px;
    color: #403838;
    padding: 12px;
}

#note-title {
    border: 1px solid #c6c6c8;
    border-radius: 10px;
    padding: 12px;
    font-size: 16px;
    background: transparent;
}

#note-title:focus-visible {
    outline: none;
}

.note-composer .composer-save {
    width: max-content;
    height: 36px;
    line-height: 12px;
    padding: 12px 24px;
    border-radius: 20px;
    border: none;
    background: var(--img-navy);
    color: white;
    font-family: inherit;
    margin-left: auto;
    margin-top: 16px;
}

.composer-cancel,
.composer-preview {
    all: unset;
    cursor: pointer;
    font-size: 14px;
    color: #6b6b6b;
    padding: 8px 36px 8px 0;
}

.composer-cancel:hover,
.composer-preview:hover {
    opacity: 50%;
}

.composer-preview {
    color: #0066ae;
}

.note-composer .composer-save:hover {
    opacity: 80%;
}

.note-composer .composer-save:disabled {
    opacity: 50%;
}

.composer-link {
    color: blue;
    cursor: pointer;
}

.add-link-form label {
    display: block;
    font-size: 17px;
    padding: 0;
    margin-bottom: 10px;
}

.add-link-form input {
    padding: 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.add-link-form input.submit {
    visibility: hidden;
    height: 0;
    margin: 0;
    padding: 0;
}

#composer-toolbar-paperclip {
    width: 15px;
    opacity: 0.5;
}

.note-delete-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 30px;
    margin: 2rem;
    gap: 1rem;
}
