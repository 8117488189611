.modal-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(132, 132, 132, 0.5);
    z-index: 10;
}

.modal {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    position: relative;
    max-height: 90%;
    overflow-y: scroll;
}

.modal-close {
    position: absolute;
    appearance: unset;
    border: none;
    background-color: unset;
    top: 20px;
    right: 20px;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
}

.modal-close:hover {
    background-color: #eee;
}

.modal-title {
    display: block;
    margin-top: 20px;
    margin-left: 22px;
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
}

.modal-subtitle {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    color: var(--text-secondary);
}
