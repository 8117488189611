.scheduled-messages-modal {
    width: 520px;
    min-height: 450px;
}

.scheduled-messages-modal-list {
    max-height: 430px;
    overflow-y: scroll;
    padding-bottom: 22px;
}

.scheduled-messages-modal-list-item:last-child hr {
    display: none;
}

.scheduled-messages-modal-row {
    padding: 20px 22px 0 22px;
}

.scheduled-messages-modal-row-text {
    flex-grow: 1;
    gap: 0.3rem;
}

.scheduled-messages-modal-row hr {
    margin-top: 20px;
}

.scheduled-messages-modal-row-message {
    font-weight: 400;
    font-size: 16px;
}

.scheduled-messages-modal-row-time {
    font-weight: 400;
    font-size: 12px;
    color: var(--text-secondary);
}

.scheduled-messages-modal-row-delete {
    border: none;
    background-color: unset;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #db273d;
    cursor: pointer;
}
