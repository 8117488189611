.notes {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1024px;
    width: 100%;
}

.new-note {
    width: max-content;
    padding: 12px 24px;
    border-radius: 20px;
    border: none;
    background-color: var(--img-navy);
    color: #FFFFFF;
    height: 40px;
}

.new-note:hover {
    opacity: 0.8;
}

.new-note:disabled {
    opacity: 0.5;
}

.note-timestamp {
    display: inline-block;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    color: #6B6B6B;
    padding: 8px 8px 0 0;
}
