.send-button {
    padding: 0;
    display: flex;
}

.send-button--disabled {
    opacity: 40%;
    pointer-events: none;
}

.send-button--separator-container {
    background-color: var(--img-navy);
    display: flex;
    justify-content: center;
    align-items: center;
}

.send-button--separator {
    height: 23px;
    width: 1px;
    background-color: white;
}

.send-button--segment {
    appearance: unset;
    background-color: var(--img-navy);
    height: 40px;
    font-size: 14px;
    line-height: 32px;
    color: white;
    cursor: pointer;
    border: none;
    margin: 0;
}

.send-button--segment:hover {
    opacity: 80%;
}

.send-button--send {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-left: 18px;
    padding-right: 10px;
    font-size: 14px;
}

.send-button--dropdown {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-right: 18px;
    padding-left: 12px;
    display: flex;
    align-items: center;
}
