.tabs {
    width: 100%;
}

.tab-list {
    display: flex;
    border-bottom: 1px solid #C6C6C8;
    margin-bottom: 36px;
}

.centered-tabs .tab-list {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.tab {
    padding: 12px 0;
    width: 150px;
    font-size: 16px;
    font-weight: 600;
    color: #6B6B6B;
    text-align: center;
    cursor: pointer;
}

.tab:hover:not(.tab-selected) {
    border-bottom: 2.5px solid gray;
}

.centered-tabs .tab {
    margin-right: 0;
    width: 100%;
}

.tab-selected {
    color: var(--img-navy);
    border-bottom: 2.5px solid var(--img-navy);
}

.tab-selected:focus-visible {
    outline: none;
}

.tab-panel-selected {
    width: 100%;
    height: 100%;
    display: flex;
}