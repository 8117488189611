.admin-parent-content-container {
    background-color: white;
    padding: 2rem;
    width: 100%;
    max-width: 550px;
}

.admin-parent-view-container {
    align-items: center;
}
