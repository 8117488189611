.content.coach-home {
    max-width: 1200px;
}

.coach-home-column {
    width: 100%;
    background-color: #FFFFFF;
    border: 0.5px solid #9F9F9F;
    height: min-content;
}

.coach-clients {
    padding: 20px 40px 20px 20px;
}

.coach-home > h1 {
    margin-bottom: 24px;
}

.coach-home .section-header {
    margin: 0 0 16px 0;
}

.broadcast-button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-size: 12px;
}

.broadcast-button:hover {
    color: #6b6b6b;
}