.coach-match-preferences .table {
    width: 100%;
}
.coach-match-preferences .table td {
    outline: 1px solid black;
}

.coach-match-preferences .form {
    padding: 2rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.coach-match-preferences .form-group {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
