.note-topic-container {
    display: flex;
    flex-direction: column;
}

.note-topic-toggle {
    border: 1px solid #C6C6C8;
    border-radius: 10px;
}

.note-topic-selection {
    background: #E9E9E9;
    border-radius: 30px;
    border: none;
    color: #403838;
    padding: 12px;
    font-size: 16px;
    margin: 8px;
    display: flex;
}

.note-topic-selection:hover {
    opacity: 0.5;
    cursor: pointer;
}

.note-topic-selection img {
    padding-left: 8px;
}

.note-topic-toggle {
    display: flex;
    justify-content: space-between;
    border: 1px solid #C6C6C8;
    border-radius: 10px;
}

.note-topic-toggle ul {
    display: inline-flex;
    flex-wrap: wrap;
}

.note-topic-toggle > img {
    padding: 8px;
}

.topic-placeholder {
    font-size: 16px;
    color: #6B6B6B;
    margin: 12px;
}

.note-inputs .popover-toggle {
    padding: 0;
}

.note-inputs .popover-toggle:hover {
    opacity: 1;
    cursor: pointer;
}

.note-inputs .popover-content {
    padding: 24px;
    padding-top: 12px;
}

.note-inputs .popover-content li {
    padding: 8px;
}

.note-inputs .tab-list {
    margin-bottom: 16px;
}

.note-inputs .tab-panel-selected {
    width: 432px;
}

.note-inputs .tab-panel-selected ul {
    columns: 2;
    margin-bottom: 32px;
}

.note-inputs .tab-panel-selected li {
    min-width: 186px;
}

.note-inputs input[type="checkbox"] {
    accent-color: var(--img-navy);
}

.note-topics-popover button {
    all: unset;
    font-weight: bold;
    font-size: 14px;
    color: #0066AE;
}

.note-topics-popover button:hover {
    opacity: 80%;
}