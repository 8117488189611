.tab-container {
    margin: 0 0 1rem 0;
}

.admin-toggle-button {
    cursor: pointer;
    background: none !important;
    border: none;
    font-size: 13px;
}

.tab-container .admin-toggle-button.selected {
    text-decoration: underline solid black 3px;
    text-underline-offset: 3px;
}
.tab-container .admin-toggle-button:not(.selected) {
    opacity: 0.3;
}
