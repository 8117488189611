.table {
    width: 100%;
}
.table td {
    font-size: 12px;
    padding: 0.5rem 1rem;
    outline: 1px solid black;
}

.usage-link {
    color: var(--img-navy);
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}
