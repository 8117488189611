.coaching-assignment {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.assignment-title {
    width: 100%;
    margin: 36px 0 12px 0;
}
