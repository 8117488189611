#list-container > div {
    margin-left: 1rem;
    margin-right: 1rem;
    min-width: 320px;
}

.admin-list-container {
    background-color: white;
    padding: 1.5rem;
    height: fit-content;
    width: fit-content;
}

.admin-list-container > .section-header {
    margin: 0 0 15px 0;
    font-weight: 700;
}

.admin-list-container ul {
    list-style: none;
    padding-left: 0;
}

.admin-list-container ul > li {
    margin-left: 0;
}
