.admin-create-coach-form label {
    display: block;
}

.admin-create-coach-form input {
    margin-bottom: 20px;
    width: 300px;
}

.admin-create-coach-form-container {
    text-align: center;
    margin-top: 2rem;
}
