.date-time-input {
    background: #F8F8F8;
    border: 1px solid #9F9F9F;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 0 22px;
    flex-shrink: 0;
}

.date-time-input-icon {
    width: 16px;
    height: 16px;
}

.date-time-input-text {
    font-size: 16px;
    line-height: 20px;
    color: #403838;
    flex-grow: 1;
}

.date-time-input-chevron {
    filter: brightness(0);
}