.messaging-container {
    display: flex;
    flex-direction: row;
    align-items: initial;
    flex-grow: 1;
    max-height: 65vh;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    box-sizing: border-box;
}

.line-break-hint {
    font-size: 12px;
    color: #6b6b6b;
    position: absolute;
    right: 0;
    padding: 12px;
}

.sendbird-avatar,
.incoming .sendbird-message-content__left,
.sendbird-icon-info,
.sendbird-message-item-reaction-menu__trigger,
.sendbird-emoji-reactions__add-reaction-badge
{
    display: none !important;
}
