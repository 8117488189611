.scheduled-messages-banner {
    display: flex;
    align-items: center;
    padding: 12px 20px;
}

.scheduled-messages-banner-text {
    margin-left: 8px;
    margin-right: 4px;
    font-size: 12px;
    line-height: 20px;
}

.scheduled-messages-banner-button {
    font-size: 12px;
    line-height: 20px;
    background-color: unset;
    appearance: unset;
    border: none;
    color: rgb(40, 100, 164);
    cursor: pointer;
    align-items: center;
    margin: 0;
    padding: 0;
}

.scheduled-messages-banner-button:hover {
    text-decoration: underline;
}
