:root {
    --img-navy: #17344f;
    --img-ocean: #0066ae;
    --text-secondary: #6b6b6b;
    --toolbar-height: 74px;
    --img-info: #fdc500;
    --img-success: #66dda4;
    --img-grey: #6b6b6b;
    --img-cherry: #db273d;
}

/* https: //codepen.io/sosuke/p */
.svg-tint-0F2B5B {
    filter: brightness(0) saturate(100%) invert(13%) sepia(31%) saturate(3411%) hue-rotate(199deg)
        brightness(95%) contrast(97%);
}

.svg-tint-9E9E9E {
    filter: invert(60%) sepia(39%) saturate(19%) hue-rotate(316deg) brightness(96%) contrast(92%);
}

.svg-tint-6B6B6B {
    filter: invert(42%) sepia(0%) saturate(0%) hue-rotate(218deg) brightness(97%) contrast(87%);
}

div,
h1,
h2,
h3,
h4,
h5,
a,
p {
    margin: 0;
    padding: 0;
}

html,
body,
#root {
    background-color: #f5f6f7;
    height: 100%;
}

a {
    text-decoration: none;
    color: unset;
}

a.text-link {
    color: var(--img-ocean);
}

a:hover {
    color: rgba(0, 0, 0, 0.6);
}

.no-li-style {
    margin: 0;
    padding: 0;
}

.no-li-style li {
    list-style-type: none;
}

.container {
    display: flex;
    flex-direction: column;
}

.content {
    margin: 0 auto;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;
}

.card {
    background-color: white;
    padding: 20px;
    border: 0.5px solid #e6e7e8;
    box-sizing: border-box;
    box-shadow: 0 0 6px rgba(171, 171, 171, 0.25);
    border-radius: 8px;
    text-align: center;
}

h1 {
    font-size: 24px;
    font-weight: 700;
}

h2 {
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
}

h2.section-header {
    margin: 35px 0 15px 0;
}

h3 {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
}

h4 {
    font-weight: 700;
    font-size: 14px;
}

.secondary {
    color: var(--text-secondary);
    font-size: 15px;
    line-height: 20px;
}

.truncate {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pill {
    appearance: unset;
    background-color: var(--img-navy);
    height: 40px;
    width: 100%;
    min-width: max-content;
    max-width: 300px;
    margin: auto;
    border: none;
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 14px;
    line-height: 32px;
    color: white;
    cursor: pointer;
}

.pill.pill-destructive {
    background-color: var(--img-cherry);
}

.pill:hover {
    opacity: 80%;
}

.pill:disabled {
    opacity: 50%;
}

.pill-outline {
    appearance: unset;
    background-color: transparent;
    height: 40px;
    line-height: 40px;
    margin: auto;
    border: 0.5px solid #17344f;
    border-radius: 20px;
    padding: 0 24px;
    font-size: 14px;
    color: #17344f;
    cursor: pointer;
}

.pill-outline.pill-destructive {
    color: var(--img-cherry);
    border-color: var(--img-cherry);
}

.pill-outline:hover {
    opacity: 80%;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
}

.unread-count {
    color: white;
    background-color: var(--img-navy);
    border-radius: 10px;
    padding: 5px 20px;
}

.d-flex {
    display: flex;
}
.flex-row {
    flex-direction: row;
}
.flex-col {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-no-wrap {
    flex-wrap: nowrap;
}
.justify-content-center {
    justify-content: center;
}
.justify-content-start {
    justify-content: flex-start;
}
.justify-content-end {
    justify-content: flex-end;
}
.justify-content-space-between {
    justify-content: space-between;
}
.justify-content-evenly {
    justify-content: space-evenly;
}
.align-items-center {
    align-items: center;
}
.align-items-end {
    align-items: end;
}

.float-right {
    float: right;
}
.float-left {
    float: left;
}
/* margins */
.m-auto {
    margin: auto;
}
.m-h-auto {
    margin: 0 auto;
}
.m-1 {
    margin: 0.25rem;
}
.m-2 {
    margin: 0.5rem;
}
.m-3 {
    margin: 0.75rem;
}
.m-4 {
    margin: 1rem;
}
/* padding */
.p-auto {
    padding: auto;
}
.p-1 {
    padding: 0.25rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-3 {
    padding: 0.75rem;
}
.p-4 {
    padding: 1rem;
}

button.btn {
    border: 1px solid black;
    background: none;
    padding: 0.3rem 0.5rem;
    color: var(--img-grey);
}

button:enabled {
    cursor: pointer;
}
