.scheduled-send-custom-time-modal {
    width: 520px;
}

.scheduled-send-custom-time-modal-input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 22px 22px;
}

.scheduled-send-custom-time-modal-button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
    margin: 0 22px 22px;
}

.scheduled-send-custom-time-modal-cancel-button {
    background-color: unset;
    border: none;
    color: var(--img-navy);
    cursor: pointer;
}

.scheduled-send-custom-time-modal-submit-button {
    max-width: 144px;
    padding: 0;
}

.scheduled-send-custom-time-modal-recipient-time {
    font-size: 12px;
    color: var(--text-secondary);
    margin-left: 22px;
}

.react-datepicker__time-container, .react-datepicker__time-box  {
    width: 150px !important;
}

.react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
}