.admin-convert-parent h1 {
    margin-top: 64px;
    margin-bottom: 24px;
}

.admin-convert-parent .modal {
    overflow-y: unset;
    padding: 16px;
}

.admin-convert-parent input[type="text"] {
    border-width: 0.5px;
    border-radius: 4px;
    padding: 8px;
    margin: 16px 0;
}