.admin-invited-text {
    color: var(--img-info);
}

select {
    padding: 0.25rem;
    color: var(--img-grey);
}

.admin-sessions .date-header {
    font-size: 14px;
    font-weight: bold;
    background-color: #E9E9E9;
    flex-grow: 1;
    padding: 12px var(--inset);
}

.admin-sessions .session-row {
    display: flex;
    align-items: center;
    margin: 16px var(--margin-right) 16px var(--spacing);

    --spacing: 20px;
}

.admin-sessions .session-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.admin-sessions .session-time {
    margin-left: 8px;
    color: #403838;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
}

.admin-sessions {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-left: 12px;

    --inset: 20px;
    --spacing: 24px;
    --margin-right: 40px;
}

.feedback-row {
    border-top: 1px solid #9f9f9f;
    padding: 12px 0;
}

.feedback-inquiry-summary {
    border-top: 1px solid #9f9f9f;
    border-bottom: 1px solid #9f9f9f;
    background-color: #E9E9E9;
    flex-grow: 1;
    padding: 12px var(--inset);
    font-weight: bold;
}

.admin-send-feedback-modal {
    width: 400px;
    max-height: 900px;
    padding: 20px;
}

.send-feedback-parent-row {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #9f9f9f;
    padding: 12px 12px 12px 0;
}