.modal {
    display: flex;
    flex-direction: column;
    color: #403838;
}

.confirm-modal-title {
    background-color: #F5F6F7;
    padding: 36px;
    font-size: 19px;
    font-weight: 700;
}

.modal-close {
    margin: 8px 0;
}

.confirm-modal-title button {
    border: none;
    background-color: transparent;
    padding: 0;
    padding-left: 64px;
}

.confirm-modal-body {
    padding: 36px;
    font-size: 16px;
    background-color: #FFFFFF;
    flex-grow: 1;
    width: 480px;
}

.confirm-modal-body p {
    padding-bottom: 16px;
}

.confirm-modal-body li {
    padding: 4px 0;
    display: flex;
    align-items: center;
}

.confirm-modal-body input {
    margin-right: 16px;
    accent-color: var(--img-navy);
}

.confirm-modal-body label {
    padding: inherit;
}

.confirm-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #F5F6F7;
    padding: 36px;
}

.confirm-modal-footer button.secondary {
    background-color: transparent;
    border: 1px solid var(--img-navy);
    color: var(--img-navy);
}

.confirm-modal-footer button {
    min-width: 128px;
    padding: 12px 24px;
    border-radius: 50px;
    border: none;
    background: var(--img-navy);
    color: white;
    font-family: inherit;
}

.confirm-modal-footer button:disabled {
    opacity: 50%;
}


.confirm-modal-footer button:hover {
    opacity: 0.5;
}

.confirm-modal-footer button.destructive {
    background-color: #ED0039;
}
