.avatar-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    width: 100%;
}

.avatar-row .right-column {
    border-top: 1px solid #9f9f9f;
    height: 100%;
    flex-grow: 1;
    padding: 18px 0 18px;
}

.avatar-row .title-column {
    flex-grow: 1;
    gap: 0.75rem;
}

.avatar-row a:hover {
    color: var(--text-secondary);
}

.avatar-row .unread-count {
    margin-right: 24px;
}
