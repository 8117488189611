.note-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.note-preview img {
    max-width: 240px;
    padding: 16px 0;
    max-height: 400px;
}

.note-preview span {
    font-size: 12px;
    font-style: italic;
    color: #6B6B6B;
}
