.avatar-small {
    height: 27px;
    width: 27px;
    border-radius: 50%;;
}

.avatar-large {
    height: 54px;
    width: 54px;
    border-radius: 50%;
}