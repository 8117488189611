.toolbar {
    height: var(--toolbar-height);
    width: 100%;
    background-color: var(--img-navy);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toolbar .logo {
    margin: auto;
}

.toolbar button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: unset;
}

.popup-menu {
    position: absolute;
    width: 260px;
    top: 60px;
    padding: 0;
    right: 50px;
    z-index: 1;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    background-color: white;
    overflow: hidden;
}

.popup-menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
}

button.popup-menu-item:hover {
    background-color: #eee;
}

.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 27px;
    margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--img-navy);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--img-navy);
}

input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}