.attachment-button {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    height: 32px;
    width: 32px;
    border-radius: 4px;
}

.attachment-button:hover {
    background-color: var(--sendbird-light-onlight-04);
}

.attachment-button input {
    display: none;
}
