.coach-sessions {
    margin-left: 12px;
    margin-bottom: 12px;
    width: 100%;

    --inset: 20px;
    --spacing: 24px;
    --margin-right: 40px;
}

.coach-sessions .section-header {
    margin: var(--inset) 0 16px var(--inset);
}

.coach-sessions hr {
    margin-left: 69px;
    margin-right: var(--margin-right);
    background-color: #9F9F9F;
    height: 0.5px;
    border: none;
}

.coach-sessions .date-header {
    font-size: 14px;
    font-weight: bold;
    background-color: #E9E9E9;
    flex-grow: 1;
    padding: 12px var(--inset);
}

.coach-sessions .session-row {
    display: flex;
    align-items: center;
    margin: 16px var(--margin-right) 16px var(--spacing);

    --spacing: 20px;
}

.session-row .popover-content button {
    color: #ED0039;
}

.session-row .session-icon {
    margin-right: var(--spacing);
}

.coach-sessions .session-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.coach-sessions .session-time {
    color: #403838;
}

.coach-sessions .session-title {
    margin: 4px 0;
}

.coach-sessions .session-confirmation {
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.session-confirmation .secondary {
    margin-bottom: 2px;
}

.session-confirmation .pill {
    padding: 0 16px;
    margin: 0 8px;
}

.coach-sessions .confirmation-icon {
    width: 12px;
    margin-right: 4px;
}

.coach-sessions .pill-outline {
    margin-left: var(--spacing);
}

.coach-sessions .plain-button {
    all: unset;
}

.coach-sessions button:hover {
    opacity: 50%;
}

.coach-sessions .sessions-footer {
    font-size: 14px;
    color: #787878;
    text-align: center;
    margin: 24px 0px;
}

.coach-sessions .sessions-empty-state {
    font-style: italic;
    font-size: 14px;
    color: var(--text-secondary);
    text-align: center;
    max-width: 300px;
    margin: 40px auto;
}

.coach-sessions .blue-link {
    min-width: max-content;
    font-size: 14px;
    color: #0066AE;
}

.session-row .popover-toggle {
    padding: 12px 0 12px 24px;
}
