.badge {
    color: white;
    font-size: 12px;
    text-align: center;
    padding: 0.25rem 0.5rem;
    border-radius: 25px;
    margin-left: 0.5rem;
    max-width: fit-content;
}

.badge.badge-success {
    background-color: var(--img-success);
}
.badge.badge-info {
    background-color: var(--img-info);
}
.badge.badge-danger {
    background-color: var(--img-cherry);
}
