.admin-delete-user h1 {
    margin-top: 64px;
    margin-bottom: 24px;
}

.admin-delete-user .modal {
    overflow-y: unset;
    padding: 16px;
}

.admin-delete-user input[type="text"] {
    border-width: 0.5px;
    border-radius: 4px;
    padding: 8px;
    margin: 16px 0;
}

.admin-delete-user input[type="text"].error {
    border-color: var(--img-cherry);
}

.admin-delete-user .pill.pill-destructive {
    margin: 16px auto;
}

.admin-delete-user .error-message {
    color: var(--img-cherry);
}
