.channels-list {
    height: 100%;
    background: white;
    border-left: 1px solid var(--sendbird-light-onlight-04);
    border-top: 1px solid var(--sendbird-light-onlight-04);
    border-bottom: 1px solid var(--sendbird-light-onlight-04);
    margin-bottom: 1px;
    box-sizing: border-box;
    max-width: 375px;
}

.channels-list .channels-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    width: 375px;
    border-bottom: 1px solid var(--sendbird-light-onlight-04);
    padding: 0 20px;
    box-sizing: border-box;
}

.channels-list .channels-list-header h1 {
    font-size: 18px;
}

.channels-list .channels-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

