.scheduled-send-dropdown {
    position: absolute;
    right: 10px;
    bottom: 60px;
    max-width: 184px;
    z-index: 1;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    background-color: white;
    border-radius: 4px;
    padding-bottom: 8px;
}

.scheduled-send-dropdown-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    color: var(--text-secondary);
    padding: 5px 12px;
}

.scheduled-send-dropdown-item {
    appearance: unset;
    background-color: white;
    border: none;
    font-size: 14px;
    line-height: 25px;
    width: 100%;
    text-align: left;
    padding-left: 12px;
    cursor: pointer;
}

.scheduled-send-dropdown-item:hover {
    background-color: #eee;
}