.admin-search .result-attr {
    font-style: italic;
    font-size: 12px;
}

.admin-search table {
    border-spacing: 16px 0;
}

.admin-search .column-header {
    text-align: left;
}

.search-bar > *:not(:first-child) {
    margin: 4px 8px;
}

.admin-search input {
    font-size: 16px;
    border-radius: 36px;
    outline: none;
    border: 0.5px solid grey;
}

.admin-search input[type=text] {
    min-width: 300px;
    margin: 8px 0;
}

input[type=checkbox] {
    margin: 0;
}

.admin-search select {
    outline: none;
    border: 0.5px solid grey;
    border-radius: 12px;
}

.admin-search .page-progress {
    padding: 0 8px;
}

.admin-search fieldset {
    border: none;
    text-transform: capitalize;
}

.admin-search fieldset strong {
    width: 160px;
    display: inline-block;
}

.admin-search fieldset > * {
    padding: 0 4px;
}

.admin-search button {
    margin: 4px;
    padding: 8px 16px;
    border-radius: 32px;
    border: 0.5px solid grey;
}
