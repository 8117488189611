.popover-toggle {
    all: unset;
    padding-left: 20px;
}

.popover-toggle:hover {
    opacity: 50%;
}

.popover-content {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 6px 12px;
    cursor: pointer;
    z-index: 10;
}

.popover-basic-menu {
    display: flex;
    flex-direction: column;
    color: #403838;
    font-size: 14px;
}

.popover-basic-menu button {
    all: unset;
    padding: 6px 0;
}

.popover-basic-menu button:hover {
    opacity: 80%;
}