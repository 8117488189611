.sign-in {
    max-width: 360px;
}

.sign-in .card {
    margin-top: 20px;
    text-align: left;
}

.sign-in label {
    display: block;
    font-size: 17px;
    margin-bottom: 10px;
}

.sign-in .text-input {
    padding: 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
}