.notes-list {
    width: 100%;
}

.notes-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
}

.note-entry {
    margin: 48px 0;
}

.note-entry h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
}

.note-entry {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    background-color: transparent;
    color: #403838;
    width: 100%;
    font-family: inherit;
    padding: 0;
}

.note-entry-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 12px;
}

.note-entry-info {
    display: flex;
    flex-direction: column;
}

.note-entry-info h5 {
    font-weight: 400;
    color: #6b6b6b;
}

.note-topics ul {
    display: flex;
    flex-wrap: wrap;
}

.note-topics h4 {
    font-size: 16px;
    font-weight: 400;
    margin: 8px 0;
}

.note-topic {
    background: #e9e9e9;
    border-radius: 30px;
    border: none;
    color: #403838;
    padding: 12px 16px;
    font-size: 16px;
    margin-right: 8px;
    display: flex;
}

.note-entry-header .delete-note {
    color: #ed0039;
}

.notes-list .ProseMirror {
    border: 1px solid #c6c6c8;
    border-radius: 10px;
}

.empty-notes {
    margin: 36px 0;
    font-style: italic;
}

.shared-preview {
    all: unset;
    font-style: normal;
    color: #0066ae;
    margin: 0 8px;
}

.previous-notes-container {
    margin-top: 3rem;
}
.previous-notes-container hr {
    width: 100%;
}
.previous-notes-container .title {
    font-size: 20px;
    font-weight: 600;
}
.link {
    color: var(--img-navy);
    cursor: pointer;
}
