.deep-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2em;
    text-align: center;
}

.deep-link p.text {
    font-size: 12px;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
}

.deep-link .logo {
    height: 100px;
    background-color: var(--img-navy);
    -webkit-mask: url(/public/logo-compact.svg) no-repeat center;
    mask: url(/public/logo-compact.svg) no-repeat center;
    margin-bottom: 1rem;
}
