.sendbird-conversation__footer {
    padding: 0;
    margin: 0;
}

.message-input {
    border: none;
    min-height: 61px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    border-top: 1px solid var(--sendbird-light-onlight-04);
    font-size: 14px;
    padding-right: 12px;
    box-sizing: border-box;
}

.message-input--placeholder {
    position: absolute;
    left: 12px;
    color: gray;
}

.message-input textarea {
    height: 0;
    min-height: 61px;
    max-height: 500px;
    overflow-y: scroll;
    resize: none;
    border: none;
    box-shadow: none;
    outline: none;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 20px 0 20px 12px;
    font: inherit;
}

.timezone-warning {
    padding: 8px 20px;
    font-size: 12px;
}