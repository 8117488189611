.banner {
    text-align: center;
    padding: 12px;
    background: linear-gradient(90deg, #66DDA4, #92FAE9);
    color: #403838;
    font-weight: 500;
    font-size: 17px;
}

.banner-link {
    color: #0066AE;
}
