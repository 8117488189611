.admin-athlete-details-summary p {
    padding-bottom: 10px;
}

.admin-athlete-content-container h4 {
    font-weight: 600;
    margin-top: 20px;
}

.admin-athlete-view-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.admin-athlete-content-container {
    background-color: white;
    padding: 2rem;
    width: 100%;
    max-width: 550px;
}

.admin-athlete-details-summary-content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.admin-athlete-header-title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.admin-athlete-discipline-selector > * {
    margin-bottom: 1rem;
    padding: 1rem 0;
}

.admin-athlete-discipline-selector i {
    color: #666;
}

.admin-athlete-discipline-selector .avatar-row .right-column {
    border-top: 0px !important;
}

h2.section-header {
    margin: 0 0 10px 0;
    font-weight: 700;
    margin-top: 0;
}

.admin-athlete-content-container hr {
    opacity: 0.5;
}

button.text-link {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--img-ocean);
}

.assignment-options {
    margin: 0.5rem 0;
}

.churn-modal-content {
    padding: 2rem;
}

.churn-modal-content > * {
    margin: 0.5rem 0;
}

.btn-lg {
    width: 100px;
    height: 40px;
    font-size: 15px;
}
