.feedback-form {
    margin: 0 auto;
    padding: 32px 0 32px 0;
    width: 100%;
    max-width: 1212px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.nps-row {
    max-width: 710px;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
}

.nps-row button {
    margin: 0 4px;
    width: 46px;
    max-width: 46px;
    height: 54px;
    background-color: white;
    border: 1px solid #E9E9E9;
    box-shadow: 0 4px 4px rgba(218, 218, 218, 0.25);
    border-radius: 5px;
    color: black;
    font-size: 16px;
}

.nps-row button.selected {
    background-color: var(--img-navy);
    color: white;
}

.feedback-form .primary {
    width: 80%;
}

.experience-row {
    max-width: 680px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 60px 0 0 0;
}

.experience-row button {
    width: 112px;
    max-width: 112px;
    height: 175px;
    margin: 0 1px;
    padding: 0 1px 8px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid #EEEEEE;
    box-shadow: 0 4px 4px rgba(218, 218, 218, 0.25);
    border-radius: 5px;
    overflow: clip;
    color: black;
    font-size: 14px;
}

.experience-row button.selected {
    background-color: var(--img-navy);
    color: white;
}

.experience-row img {
    margin: 32px 0 20px 0;
    width: 100%;
    max-width: 50px;
}

.feedback-form textarea {
    width: 80%;
    height: 212px;
    border-radius: 10px;
    border: 1px solid #E9E9E9;
    box-shadow: 0 4px 4px rgba(218, 218, 218, 0.25);
    padding: 20px 20px;
    font: inherit;
    font-weight: 400;
    font-size: 17px;
    margin: 40px 8px 0 8px;
}

.feedback-form hr {
    width: 100%;
    margin: 80px 0 80px 0;
}

.feedback-submitted {
    align-content: center;
    text-align: center;
}


@media (min-width: 601px) {
    .small-screen-only {
        display: none;
    }

    .nps-row {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (max-width: 600px) {
    .large-screen-only {
        display: none;
    }

    .nps-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 340px;
        width: 100%;
    }

    .nps-row {
        max-width: 340px;
        width: available;
    }

    .nps-row .button {
        margin: 0 12px;
        min-width: 32px;
    }

    .experience-row {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .experience-row button {
        flex-direction: row;
        width: 90%;
        max-width: 320px;
        height: 64px;
        margin-top: 10px;
        padding: 12px 20px;
        text-align: left;
    }

    .experience-row img {
        margin: 0 20px 0 0;
        width: 100%;
        height: 40px;
    }
}